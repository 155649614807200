<template>
  <footer class="footer">
    <div class="footer__top">
      <div class="footer__container container">
        <a class="footer__logo" href="#main">
          <logo-component
            :color="'#ffffff'"
          />
        </a>
        <ul class="footer__menu menu">
          <!--<li><a href="#benefits">Преимущества</a></li>
          <li><a href="#products">Продукция</a></li>
          <li><a href="#banner-form">Приобрести</a></li>
          <li><a href="#problems">Частые проблемы</a></li>
          <li><a href="#banner-instruction">Подобрать средство</a></li>!-->
          <li @click="menuOpened = !menuOpened"><a href="#products">Каталог товаров</a></li>
          <li @click="menuOpened = !menuOpened"><a href="#benefits">Преимущества</a></li>
          <li @click="menuOpened = !menuOpened" class="li_button li_button_mobile"><button-default
                :color="'blue'"
                :text="'Для оптовых покупателей'"
                @click="openPopup('request')"
              /></li>
          <li @click="menuOpened = !menuOpened" class="li_button li_button_mobile"><button-default
                :color="'green'"
                :text="'Где купить'"
                @click="openPopup('modal')"
              /></li>
        </ul>
        <div class="footer__contacts">
          <a href="tel:+79061667755">
            +7 906 166 77 55
          </a>
          <a href="mailto:info@aquadacha.ru">
            info@aquadacha.ru
          </a>
        </div>
        <div class="footer__marketplace">
          <p class="footer__name_block">Мы на маркетплейсах:</p>
          <div class="footer__link_market">
            <a href="https://www.wildberries.ru/seller/1317420?sort=popular&amp;page=1&amp;fbrand=280298" class="footer__icon_market">
              <img src="/images/market-icon/wildber.png" alt="wildberries">
              <p class="footer__upper_case">wildberries</p>
            </a>
            <a class="footer__icon_market" href="https://www.ozon.ru/seller/aqualeon-1239176/brand/akvadacha-100357132/?miniapp=seller_1239176">
              <img src="/images/market-icon/ozon.png" alt="ozon">
              <p class="footer__upper_case">ozon</p>
            </a>
            <a class="footer__icon_market" href="https://market.yandex.ru/search?businessId=58301735&amp;text=%D0%B0%D0%BA%D0%B2%D0%B0%D0%B4%D0%B0%D1%87%D0%B0&amp;rs=eJwzamIOYDzKyHBA0RZIMoTsAZIJJ22A5IJqEKnAvxtIOnCD2A-EdwHJhmyQGgZrsJr5IJJhAkj8ASNI5YMCa5C4KMi0BVgkZdglRFWIFIEJJvACzLhwSVLkF5XkEqFD2Dx9yARB1GQeod-sDkQSRDC9glviCyYSaYXbsfpL4YLKILdkMfiEyYCDL5wDMwOxpkcsNRkAkNcSC2QzvIPQc-gf0yD8ROWAKWPQAWOQ5iO-wEmxlgA7e3oQPk8oaFNnBSIRvMLgDb4gomVUD2NqSA2SvBegPBpp0Duz8Q7CMdkJoFU8F2zQSFWIICOBym2AAAeuGDeQ%2C%2C&amp;allowCollapsing=1&amp;local-offers-first=0">
              <img src="/images/market-icon/yandex.png" alt="Yandex market">
              <p class="footer__upper_case">яндекс маркет</p>
            </a>
            <a class="footer__icon_market" href="https://megamarket.ru/shop/aqualeon/catalog/dacha-sezonnye-tovary/#?filters=%7B%222B0B1FF4756D49CF84B094522D57ED3D%22%3A%5B%22%D0%90%D0%BA%D0%B2%D0%B0%D0%94%D0%B0%D1%87%D0%B0%22%5D%7D">
              <img src="/images/market-icon/sbermarket.png" alt="megamrket">
              <p class="footer__upper_case">мегамаркет</p>
            </a>
            <a class="footer__icon_market" href="https://volgograd.leroymerlin.ru/search/?q=Aqualeon">
              <img src="/images/market-icon/leroy.png" alt="lerua merlen">
              <p class="footer__upper_case">леруа мерлен</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__bottom">
      <div class="footer__container container">
        <p class="footer__company">©2022 ООО «АКВАДАЧА»</p>
        <h3 class="footer__title title title--medium">Средства по уходу за водой в бассейне</h3>
<!--        <div class="footer__social">-->
<!--          <a class="vk" href="#">-->
<!--          </a>-->
<!--          <a class="youtube" href="#">-->
<!--          </a>-->
<!--        </div>-->
      </div>
    </div>
    <modal-component
      :isOpened="modal"
      @open-popup="openPopup"
      @close-popup="closePopup"
    />
    <request-popup
      :isRequestPopup="isRequestPopup"
      @open-popup="openPopup"
      @close-popup="closePopup"
    />
    <success-popup
      :isSuccessPopup="isSuccessPopup"
      @open-popup="openPopup"
      @close-popup="closePopup"
    />
  </footer>
</template>

<script>
import LogoComponent from './ui/LogoComponent.vue'
import ButtonDefault from './ui/ButtonDefault.vue'
import ModalComponent from './blocks/ModalComponent.vue'
import RequestPopup from '@/components/blocks/RequestPopup'
import SuccessPopup from '@/components/blocks/SuccessPopup'

export default {
  components: {
    LogoComponent,
    SuccessPopup,
    ModalComponent,
    RequestPopup,
    ButtonDefault
  },
  data () {
    return {
      isScroll: false,
      limitPosition: 80,
      lastPosition: 0,
      modal: false,
      isRequestPopup: false,
      isSuccessPopup: false,
      menuOpened: false
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
        this.isScroll = true
      }
      if (this.lastPosition > window.scrollY && this.limitPosition > window.scrollY) {
        this.isScroll = false
      }
      this.lastPosition = window.scrollY
    },
    widthScrollBar () {
      const div = document.createElement('div')
      div.style.overflowY = 'scroll'
      div.style.width = '50px'
      div.style.height = '50px'
      document.body.append(div)
      const scrollWidth = div.offsetWidth - div.clientWidth
      div.remove()
      this.scrollWidth = `${scrollWidth}px`
    },
    scrollBarVisibility () {
      if (this.isProductPopup || this.isRequestPopup || this.isSuccessPopup || this.isQuizPopup) {
        document.querySelector('html').style.overflow = 'hidden'
        document.querySelector('html').style.marginRight = this.scrollWidth
        document.querySelector('header').style.marginRight = this.scrollWidth
      } else {
        document.querySelector('html').style.overflow = 'auto'
        document.querySelector('html').style.marginRight = '0px'
        document.querySelector('header').style.marginRight = '0px'
      }
    },
    closePopup (popup, data) {
      this.isOpened = false
      this.modal = false
      this.isQuizPopup = false
      this.isRequestPopup = false
      this.isSuccessPopup = false
      this.scrollBarVisibility()
      this.nextStep()
      if (popup === 'success') {
        this.isSuccessPopup = true
      }
    },
    openPopup (popup) {
      switch (popup) {
        case 'quiz':
          this.isOpened = true
          this.isQuizPopup = true
          break
        case 'request':
          this.isRequestPopup = true
          break
        case 'modal':
          this.modal = true
          break
      }
      this.scrollBarVisibility()
    },
    nextStep (value) {
      this.isQuizStart = value
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: $mainColor;
  color: $baseColor;

  &__logo {
    & > svg {
      max-width: 109px;
      display: block;
    }
  }

  &__menu {
    display: none;
  }

  &__contacts {
    text-align: right;
    display: flex;
    flex-direction: column;
  }

  &__top {
    border-bottom: 1px solid $baseColor;

    & .footer__container {
      padding-top: 32px;
      padding-bottom: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__bottom {
    padding-top: 22px;
    padding-bottom: 23px;

    & .footer__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
    }
  }

  &__company {
    font-size: 12px;
    line-height: 18px;
    text-align: right;
  }

  &__title {
    color: $baseColor;
    text-transform: uppercase;
    margin: 12px 0;
  }

  &__social {
    & a + a {
      margin-left: 15px;
    }
  }
  &__marketplace {
    max-width: max-content;
    width: 100%;
  }
  &__link_market {
    gap: 10px;
    display: flex;
    text-align:center;
  }
  &__icon_market{
    max-width:max-content;
    width:100%;
  }
  &__icon_market img{
    max-width: 60px;
    width: 100%;
    margin:0 auto;
  }
  &__upper_case {
    font-size: 12px;
    margin-top:5px;
  }
  &__name_block {
    text-align: end;
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 600;
  }
  @media (min-width: $tablet-min) {
    &__logo {
      & > svg {
        margin-right: 76px;
      }
    }
    &__contacts {
      margin-left: 14px;
    }
    &__bottom {
      & .footer__container {
        flex-direction: row;
      }
    }
    &__title {
      margin: 0;
    }
  }
  @media (min-width: $desktop) {
    &__container {

    }
    &__logo {
      & > svg {
        margin-right: 42px;
        max-width: 76px;
      }
    }
    &__menu {
      //width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //align-items: center;
      font-size: 13px;
      gap:8px;
    }
    &__top {
      & .footer__container {
        padding-top: 17px;
        padding-bottom: 29px;
      }
    }
    &__bottom {
      padding-top: 11px;
      padding-bottom: 11px;
      & p {
        font-size: 8px;
      }
      & h3 {
        font-size: 12px;
      }
    }
    .li_button button{
      font-size: 13px;
      height: max-content;
      background-color:unset;
      border:none;
      text-align:left;
      line-height: normal;
    }
    .li_button button:hover{
      background:unset;
      color:#fff;
    }
    .li_button_mobile button{
      font-size: 13px;
    }
  }
  @media (min-width: 1440px) {
    &__logo {
      & > svg {
        max-width: 109px;
        margin-right: 49px;
      }
    }
    &__menu {
      //width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //align-items: center;
      font-size: 17px;
      //max-width: 940px;
      gap:8px;
    }
    &__top {
      & .footer__container {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
    &__bottom {
      padding-top: 23px;
      padding-bottom: 23px;
      & p {
        font-size: 12px;
      }
      & h3 {
        font-size: 18px;
      }
    }
    .li_button button {
        font-size: 16px;
    }
  }
  @media(max-width:768px){
    &__upper_case {
      display: none;
    }
    &__link_market {
      flex-wrap: wrap;
    }
    &__icon_market img{
      max-width: 45px;
    }
    &__name_block {
      text-align: center;
      margin-top: 15px;
    }
    &__top {

      & .footer__container {
        flex-direction: column;
      }
    }
  }
}
</style>
