<template>
  <section class="main" id="main">
    <div class="container main__container">
      <div class="main__background">
        <div class="main__background--wrap" v-if="isMobile"></div>
        <div class="main__background--wrap" v-else><img src="/images/main/landspace.png" alt=""></div>
        <div class="main__mouse-background">
          <svg width="107" height="32" viewBox="0 0 107 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M106.881 31.3599C72.4177 31.3599 80.734 0 52.5797 0C23.4671 0 35.6164 31.3599 0.0128174 31.3599H106.892H106.881Z"
                  fill="#FBFBFB"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M52.9998 12C49.7002 12 47.0005 14.5147 47.0005 17.5882V25.4118C47.0005 28.4853 49.7002 31 52.9998 31C56.2995 31 58.9992 28.4853 58.9992 25.4118V17.5882C58.9992 14.5147 56.2995 12 52.9998 12ZM57.7565 25.4118C57.7565 27.8466 55.6139 29.8424 52.9998 29.8424C50.3858 29.8424 48.2432 27.8466 48.2432 25.4118V17.5882C48.2432 15.1534 50.3858 13.1576 52.9998 13.1576C55.6139 13.1576 57.7565 15.1534 57.7565 17.5882V25.4118Z"
                  fill="#565656"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M53 15.792C52.6571 15.792 52.4 16.0315 52.4 16.3508V18.9853C52.4 19.3046 52.6571 19.5441 53 19.5441C53.3428 19.5441 53.5999 19.3046 53.5999 18.9853V16.3508C53.5999 16.0315 53.3428 15.792 53 15.792Z"
                  fill="#565656"/>
          </svg>
        </div>
        <div class="main__content">
          <!--<div class="main__left">
            <button-play/>
            <p>Посмотрите видео о нашем продукте</p>
          </div>!-->
          <div class="main__center">
            <h1 class="main__title title title--bold">Идеальная вода в вашем бассейне - вместе со средствами
              «АкваДача»</h1>
            <p>Подходит для надувных и каркасных бассейнов на даче и в частном доме.</p>
            <!--<div class="main__quiz">
              <button-default
                :color="'green'"
                :text="'Подобрать средство для бассейна'"
                @click="openPopup('quiz')"
              />
              <h3 class="main__button--text title title--medium">Ответьте <span class="green">на 5 вопросов!</span></h3>
              <p class="main__button--description">и получите список средств + советы по уходу за бассейном</p>
            </div>!-->
          </div>
          <div class="main__right">
            <div class="main__contacts">
              <a href="tel:+79061667755"><h2>+7 906 166 77 55</h2></a>
              <a href="mailto:info@aquadacha.ru"><p>info@aquadacha.ru</p></a>
            </div>
            <div class="main__feedback">
              <button-default
                :color="'blue'"
                :text="'Заказать звонок'"
                :svg="'phone'"
                @click="openPopup('request')"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="main__bath"><img src="/images/main/pool.png" alt=""></div>
      <h2 class="main__title--bottom title title--bold">Средства по уходу за водой в бассейне</h2>
    </div>
    <div class="main__benefits">
      <ul class="main__list">
        <li v-for="(benefit, index) in benefits" :key="index">
          <benefit-card
            :title="benefit.title"
            :text="benefit.text"
          />
        </li>
      </ul>
    </div>
    <div class="main__label">
      <svg width="142" height="20" viewBox="0 0 142 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6.04034 16.0017L13.1509 13.7456C15.7207 13.0313 18.2135 12.4564 20.8934 11.9468C26.4669 10.8884 32.472 10.3745 38.3538 10.4464C44.1233 10.5182 50.0249 11.1476 55.4597 12.3083C62.5262 13.8174 64.0434 14.7321 70.1718 16.5417C88.9469 22.0796 108.391 20.9581 126.404 13.2186L131.19 10.9472C131.643 10.7164 132.357 10.2591 132.733 10.1262C126.995 12.2408 124.715 13.31 118.36 14.8279C101.199 18.9241 84.4238 17.7743 68.1679 12.3344C65.7853 11.5374 63.5744 10.6271 61.1168 9.86056C44.1519 4.56439 24.8572 6.33486 9.12326 14.3118C8.13012 14.8148 6.82648 15.4333 6.04034 15.9995V16.0017ZM0 16.9446C1.07022 16.3828 2.25054 15.5117 3.38682 14.8257C20.6314 4.40324 42.3969 1.64627 61.8061 8.00516C64.6908 8.95028 67.1902 9.99775 70.0375 10.9625C81.8892 14.9825 95.791 16.4633 109.191 14.6471C117.548 13.5126 127.459 10.6859 134.775 7.02084C136.323 6.24557 140.916 3.96988 141.993 3.08573C140.982 3.40585 139.28 4.31613 138.199 4.80176C122 12.0883 102.791 13.7586 85.4015 9.98686C82.3626 9.32702 79.5241 8.5082 76.6482 7.56962C73.6886 6.60489 71.2971 5.49645 68.4939 4.42284C56.7985 -0.0566902 42.8131 -1.42211 29.6203 1.66152C20.7723 3.72815 13.2808 7.07092 6.26275 11.949C4.92828 12.8767 0.83239 15.8732 0 16.9424L0 16.9446Z"
              fill="#0072B1"/>
      </svg>
    </div>
    <popup-component
      :isOpened="isOpened"
      :dataPopup="dataPopup"
      :isQuizPopup="isQuizPopup"
      @open-popup="openPopup"
      @close-popup="closePopup"
      @nextStep="nextStep(false)"
    />
    <request-popup
      :isRequestPopup="isRequestPopup"
      @open-popup="openPopup"
      @close-popup="closePopup"
    />
    <success-popup
      :isSuccessPopup="isSuccessPopup"
      @open-popup="openPopup"
      @close-popup="closePopup"
    />
  </section>
</template>

<script>
import PopupComponent from '../blocks/PopupComponent.vue'
import ButtonDefault from '../ui/ButtonDefault.vue'
import BenefitCard from '../blocks/BenefitCard.vue'
import RequestPopup from '@/components/blocks/RequestPopup'
import SuccessPopup from '@/components/blocks/SuccessPopup'

export default {
  components: {
    SuccessPopup,
    RequestPopup,
    PopupComponent,
    ButtonDefault,
    BenefitCard
  },
  data () {
    return {
      isOpened: false,
      modal: false,
      dataPopup: {},
      isQuizPopup: true,
      isRequestPopup: false,
      isSuccessPopup: false,
      isMobile: window.innerWidth <= 700,
      benefits: [
        {
          title: 'Экономичная упаковка',
          text: '- хватит на весь сезон'
        },
        {
          title: 'Адаптировано',
          text: 'к российским условиям\n'
        },
        {
          title: 'Изготовлено',
          text: 'в соответствии ГОСТам и СанПиН'
        },
        {
          title: 'Продукция',
          text: ' прошла гос. регистрацию и сертификацию'
        }
      ],
      scrollWidth: 0
    }
  },
  created () {
    this.widthScrollBar()
  },
  methods: {
    widthScrollBar () {
      const div = document.createElement('div')
      div.style.overflowY = 'scroll'
      div.style.width = '50px'
      div.style.height = '50px'
      document.body.append(div)
      const scrollWidth = div.offsetWidth - div.clientWidth
      div.remove()
      this.scrollWidth = `${scrollWidth}px`
    },
    scrollBarVisibility () {
      if (this.isProductPopup || this.isRequestPopup || this.isSuccessPopup || this.isQuizPopup) {
        document.querySelector('html').style.overflow = 'hidden'
        document.querySelector('html').style.marginRight = this.scrollWidth
        document.querySelector('header').style.marginRight = this.scrollWidth
      } else {
        document.querySelector('html').style.overflow = 'auto'
        document.querySelector('html').style.marginRight = '0px'
        document.querySelector('header').style.marginRight = '0px'
      }
    },
    closePopup (popup, data) {
      this.isOpened = false
      this.modal = false
      this.isQuizPopup = false
      this.isRequestPopup = false
      this.isSuccessPopup = false
      this.scrollBarVisibility()
      this.nextStep()
      if (popup === 'success') {
        this.isSuccessPopup = true
      }
    },
    openPopup (popup) {
      switch (popup) {
        case 'quiz':
          this.isOpened = true
          this.isQuizPopup = true
          break
        case 'request':
          this.isRequestPopup = true
          break
        case 'modal':
          this.modal = true
          break
      }
      this.scrollBarVisibility()
    },
    nextStep (value) {
      this.isQuizStart = value
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  background-image: url(/src/assets/images/main/background.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 43px;
  &__background {
    &--wrap {
      position: absolute;
      left: 0;
      right: 0;
      top: 37px;
      background-image: url(/src/assets/images/main/landspace-mobile.png);
      height: 704px;
      background-size: cover;
      background-position: 50%;
      border-radius: 0 0 30px 30px;
      & img {
        min-width: 100%;
      }
    }
  }

  &__mouse-background {
    display: none;
  }

  &__bath,
  &__left {
    order: 2;
    z-index: 1;
    position: relative;
  }

  &__left {
    align-self: baseline;
    & button {
      margin-left: 8px;

    }
    & p {
      display: none;
    }
  }

  &__content {
    margin-top: 40px;
    margin-top: 43px;
    margin-left: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 571px;
    z-index: 1;
    position: relative;
  }

  &__title {
    margin-bottom: 16px;
    margin-bottom: 23px;
    max-width: 353px;
    &--bottom {
      margin-top: 12px;
      margin: 23px auto 0;
      text-align: center;
      max-width: 257px;
    }
  }

  &__quiz {
    margin-top: 28px;
    background-color: $baseColor;
    border-radius: 37px;
    padding: 11px 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 6px;
    margin-top: 37px;
    max-width: 347px;
    & h3 {
      margin-top: 8px;
    }

    p {
      display: block;
      font-size: 9px;
      line-height: 12px;
      text-align: center;
      line-height: 19px;
    }
  }

  &__contacts {
    display: none;
  }

  &__feedback {
    margin-top: 16px;
    display: none;
  }

  &__benefits {
    margin: 68px 16px 0;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 59px;
  }

  &__center {
    font-size: 12px;
    & > p {
      display: flex;
      max-width: 95%;
      letter-spacing: 0.7px;
      max-width: 347px;
      &::before {
        content: "";
        width: 100%;
        height: 1px;
        background-color: #1e1e1e;
        top: 12px;
        margin-top: 10px;
        max-width: 50px;
        margin-right: 17px;
        margin-right: 19px;
      }
    }
  }

  &__label {
    margin-top: 69px;
    display: flex;
    justify-content: center;
    & svg {
      width: 90px;
    }
  }

  @media (min-width: $mobile-max) {
    &__list {
      display: grid;
      grid-column-gap: 16px;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 55px;
    }
    &__feedback {
      max-width: 344px;
      margin: 16px auto 0;
    }
  }
  @media (min-width: $tablet-min) {
    padding-top: 48px;
    &__mouse-background,
    &__bath,
    &__left {
      display: block;
    }
    &__container {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    &__background {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      max-height: 800px;
      border-radius: 0 0 60px 60px;
      overflow: hidden;
      z-index: 0;

      &--wrap {
        display: block;
        background-image: none;
        position: static;
        height: auto;
      }
    }

    &__bath,
    &__left {
      order: inherit;
    }

    &__bath {
      display: block;
      position: absolute;
      width: 50%;
      top: 73%;
      left: 78px;
      & img {
        width: 100%;
      }
    }
    &__mouse {
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;

      & img {
        width: 11px;
      }
    }
    &__mouse-background {
      position: absolute;
      bottom: -1px;
      left: 65%;
      display: flex;
      justify-content: center;
    }
    &__content {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      grid-gap: 0;
      display: grid;
      grid-template-columns: 4fr 2fr;
      align-items: baseline;
      margin-top: 0;
      padding: 30px 12px 18px 12px;
    }
    &__title {
      font-weight: 700;
      color: #1b1b1b;
      letter-spacing: 0.41px;
      max-width: 550px;
      font-size: 24px;
      line-height: 30px;

      &--bottom {
        width: 100%;
        color: #010101;
        opacity: 0.9;
        max-width: 300px;
        text-align: right;
        margin: 23px 0 0;
      }
    }
    &__quiz {
      max-width: 84%;
      margin-top: 0;
      overflow: hidden;

      & button {
        font-size: 15px;
        font-family: 'OpenSansBold';
      }

      &--text {
        font-size: 17px;
        font-weight: 700;
        color: #2e2e2e;
        margin-top: 12px;
        letter-spacing: 0.56px;

        & span {
          color: #54af3a;
        }
      }

      &--description {
        text-align: center;
        font-size: 13px;
        font-weight: 400;
      }

      & h3 {
        margin-bottom: 0px;
        margin-top: 8px;

      }

      & p {
        line-height: 18px;
        margin-top: 0;
      }
    }
    &__right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-self: end;
      height: 100%;
      justify-content: space-between;
      align-items: flex-end;
    }
    &__contacts {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      & a {
        transition: all .5s ease;
        &:hover {
          opacity: .7;
        }
      }

      & h2 {
        color: #000000;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0.2px;
      }

      & p {
        font-size: 15px;
        font-weight: 400;
        color: #000000;
        opacity: 0.6;
      }
    }
    &__feedback {
      position: absolute;
      bottom: 55px;

      & button {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-gap: 8px;
        padding: 12px 24px;

        & p {
          color: #ffffff;
          font-size: 15px;
          font-weight: 400;
        }
      }
    }
    &__left {
      max-width: 115px;
      align-self: flex-end;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      & p {
        font-size: 12px;
        font-weight: 400;
        opacity: 0.9;
        color: #353535;
        max-width: 98px;
        display: block;
      }
    }
    &__center {
      display: flex;
      flex-direction: column;

      & > p {
        font-size: 17px;
        font-weight: 400;
        letter-spacing: 0.2px;
        color: #0f0f0f;
        max-width: 370px;
        margin-bottom: 16px;
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;

        &::before {
          content: '';
          width: 20%;
          height: 1px;
          background-color: #1e1e1e;
          top: 12px;
          margin-right: 20px;
          margin-top: 10px;
        }
      }
    }
    &__benefits {
      margin-top: 84px;
      width: auto;
      margin-bottom: 64px;
    }
    &__list {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 8px;
      overflow: visible;
    }
    &__label {
      margin-top: 32px;
    }
  }
  @media (min-width: $tablet-max) {
  }
  @media (min-width: $desktop) {
    &__background {
      max-height: 488px;
      border-radius: 0 0 42px 42px;

      &--wrap {
        height: 100%;

        & img {
          height: 100%;
        }
      }
    }
    &__content {
      padding: 0;
      align-items: flex-start;
    }
    &__container {
      padding-right: 0;
    }
    &__mouse-background {
      left: 68%;
    }
    &__bath {
      width: 49%;
      top: 68.5%;
      left: 107px;
    }
    &__title {
      font-size: 25px;
      max-width: 424px;
      line-height: 34px;
      letter-spacing: 0;
      margin-bottom: 16px;
      &--bottom {
        margin-top: 24px;
        max-width: 339px;
        font-size: 25px;
        line-height: 34px;
      }
    }
    &__quiz {
      margin-left: 2px;
      max-width: 58%;
      padding: 11px 12px;
      border-radius: 34px;
      & button {
        padding-bottom: 4px;
        height: 44px;
        font-size: 11px;
      }

      & h3 {
        margin-bottom: 0;
        margin-top: 14px;
        font-size: 11px;
        line-height: 3px;
      }

      & p {
        margin-top: 5px;
        letter-spacing: -.4px;
        font-size: 9px;
        max-width: 90%;
      }
    }
    &__feedback {
      bottom: 22px;
      right: 57px;
      display: flex;
      & button {
        height: 36px;
        padding: 12px 22px 12px 24px;
        font-size: 9px;
        letter-spacing: 0.1px;

        &::before {
          font-size: 7px;
          margin-right: 0;
        }
      }

    }
    &__left {
      display: block;
      grid-gap: 16px;
      margin-left: 36px;
      padding-bottom: 16px;
      margin-left: 30px;
      padding-bottom: 49px;
      & button {
        margin-left: 2px;
        & > svg {
          width: 60px;
          height: 60px;
        }
      }

      & p {
        font-size: 12px;
        max-width: 89px;
        letter-spacing: .2px;
        line-height: 16px;
        font-size: 8px;
        max-width: 62px;
        letter-spacing: 0.2px;
        line-height: 9px;
      }
    }
    &__center {
      margin-top: 26px;
      padding-left: 200px;

      & > p {
        margin-left: 3px;
        max-width: 303px;
        margin-bottom: 11px;
        font-size: 11px;
        font-weight: 400;
        letter-spacing: 0;

        &::before {
          width: 20%;
          margin-right: 13px;
          margin-top: 6px;
        }
      }
    }
    &__list {
      grid-column-gap: 32px;
    }
    &__contacts {
      margin-right: 66px;
      margin-top: 31px;

      & h2 {
        font-size: 17px;
        letter-spacing: -.4px;
        line-height: 24px;
      }

      & p {
        font-size: 9px;
        letter-spacing: .4px;
      }
    }
    &__benefits {
      grid-column-gap: 28px;
      margin: 95px auto 87px;
      margin: 87px auto 87px;
      margin: 87px auto 105px;
      padding: 0 20px;
      width: 100%;
      max-width: 940px;
    }
    &__label {
      display: none;
    }
  }
  @media (min-width: 1440px) {
    &__background {
      max-height: 817px;

      &--wrap {
        height: 100%;

        & img {
          height: 100%;
        }
      }
    }
    &__content {
      padding: 0;
      align-items: flex-start;
    }
    &__container {
      padding-right: 0;
    }
    &__mouse-background {
      left: 68%;
    }
    &__bath {
      width: 49%;
      top: 68.5%;
      left: 129px;
    }
    &__title {
      font-size: 40px;
      line-height: 54px;
      letter-spacing: 0;
      margin-bottom: 24px;

      &--bottom {
        max-width: 450px;
        margin-top: 24px;
        font-size: 36px;
        line-height: 46px;
      }
    }
    &__quiz {
      margin-left: 2px;
      max-width: 60%;
      padding-bottom: 18px;

      & button {
        height: 72px;
        padding-bottom: 4px;
        font-size: 16px;
      }

      & h3 {
        margin-bottom: 0;
        margin-top: 14px;
      }

      & p {
        margin-top: 5px;
        letter-spacing: -.4px;
        font-size: 13px;
        max-width: 90%;
      }
    }
    &__feedback {
      bottom: 36px;
      right: 55px;

      & button {
        height: 58px;
        padding: 12px 37px 12px 40px;
        font-size: 14px;
        letter-spacing: .5px;

        &::before {
          font-size: 11px;
          margin-right: 0;
        }
      }

    }
    &__left {
      display: block;
      margin-left: 57px;
      padding-bottom: 80px;
      grid-gap: 16px;

      & svg {
        width: 60px;
        height: 60px;
      }

      & p {
        font-size: 12px;
        max-width: 89px;
        letter-spacing: .2px;
        line-height: 16px;
      }
    }
    &__center {
      margin-top: 75px;
      padding-left: 200px;
      & > p {
        margin-left: 3px;
        max-width: 486px;
        margin-bottom: 22px;
        font-size: 17px;
        font-weight: 400;
        letter-spacing: .2px;
        &::before {
          content: "";
          width: 20%;
          height: 1px;
          background-color: #1e1e1e;
          top: 12px;
          margin-right: 20px;
          margin-top: 10px;
        }
      }
    }
    &__list {
      grid-column-gap: 54px;
      grid-column-gap: 27px;
    }
    &__contacts {
      margin-right: 68px;
      margin-top: 80px;

      & h2 {
        font-size: 27px;
        letter-spacing: -.4px;
        line-height: 39px;
      }

      & p {
        font-size: 15px;
        letter-spacing: .4px;
      }
    }
    &__benefits {
      grid-column-gap: 28px;
      margin: 133px auto 87px;
      padding: 0 20px;
      width: 100%;
      max-width: 1466px;
    }
    &__quiz {
      max-width: 59%;
      & button {
        font-size: 18px;
      }
      & h3 {
        font-size: 18px;
        line-height: 22px;
      }
    }
    &__title {
      max-width: 545px;
      &--bottom {
        font-size: 40px;
        line-height: 54px;
        margin-top: 40px;
      }
    }
    &__bath {
      width: 53%;
      top: 68.5%;
      left: 129px;
    }
    &__benefits {
      grid-column-gap: 54px;
    }
  }
}

/* @media screen and (max-width: 1500px) {
    .main {
        &__content {
            padding: 64px 59px 48px 80px;
        }

        &__background {
            &--wrap {
                padding: 0 48px;
                top: 0;
            }
        }
    }
}

@media screen and (max-width: 980px) {
    .main {
        &__content {
            padding: 64px 20px 48px 80px;
            grid-template-columns: 1fr 4fr 3fr;
        }
        &__bath {
            display: none;
        }
    }
} */

/* @media screen and (max-width: 880px) {
    .main {
        &__quiz {
            display: flex;
            flex-direction: column;
            max-width: 478px;
            background-color: #FFFFFF;
            padding: 14px 8px 18px 8px;
            border-radius: 58px;
            display: flex;
            flex-direction: column;
            align-items: center;
            &--text {
                font-size: 14px;
                font-weight: 700;
                color: #2e2e2e;
                margin-top: 12px;
                letter-spacing: 0.56px;
                & span {
                    color: #54af3a;
                }
            }
            &--description {
                text-align: center;
                font-size: 13px;
                font-weight: 400;
            }
            & button {
                padding: 12px 20px;
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: 880px) {
    .main {
        &__quiz {
            display: flex;
            flex-direction: column;
            max-width: 478px;
            background-color: #FFFFFF;
            padding: 14px 8px 18px 8px;
            border-radius: 58px;
            display: flex;
            flex-direction: column;
            align-items: center;
            &--text {
                font-size: 14px;
                font-weight: 700;
                color: #2e2e2e;
                margin-top: 12px;
                letter-spacing: 0.56px;
                & span {
                    color: #54af3a;
                }
            }
            &--description {
                text-align: center;
                font-size: 13px;
                font-weight: 400;
            }
            & button {
                padding: 12px 20px;
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .main {
        &__mouse {
            display: none;
            &--background {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .main {
        &__title {
            font-size: 24px;
            line-height: 26px;
            &--bottom {
                font-size: 24px;
                line-height: 26px;
                max-width: 400px;
                padding-top: 40px;
            }
        }
        &__right {
            &--contacts {
                & h2 {
                    font-size: 24px;
                    line-height: 26px;
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .main {
        &__container {
            align-items: center;
        }
        &__left {
            background-color: #FFFFFF;
            padding: 20px;
            align-self: center;
            max-width: 100%;
            order: 3;
            align-items: center;
            flex-direction: row;
            border-radius: 58px;
            & p {
                text-align: center;
            }
        }

        &__title {
            font-size: 20px;
            font-weight: 900;
            letter-spacing: normal;
            text-align: center;
            &--bottom {
                font-size: 20px;
                letter-spacing: 0;
                font-weight: 900;
                text-align: center;
                padding: 16px 0px 40px 0;
            }
        }

        &__background {
            &--wrap {
                // display: none;
                position: absolute;
                left: -300px;
                z-index: -1;
            }
        }

        &__content {
            display: flex;
            position: static;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 40px 0;
            grid-gap: 20px;
        }

        &__center {
            align-items: center;
        }

        &__right {
            justify-content: center;
            height: auto;
            grid-gap: 20px;
            &--contacts {
                align-items: center;
            }
        }

        &__description {
            display: flex;
            text-align: center;
            margin: 12px 0 12px 32px;
            font-size: 16px;
            &::before {
                content: '';
                position: absolute;
                width: 20px;
                height: 2px;
                background-color: #1e1e1e;
                top: 12px;
                left: -18px;
            }
        }
        &__quiz {
            &--description {
                max-width: 340px;
            }
        }
    }
} */
</style>
