<template>
  <section class="products" id="products">
    <div class="products__container container">
      <h2 class="products__title title title--bold">Наша продукция - все, что необходимо вашему бассейну!</h2>
      <p class="products__description">В нашем ассортименте каждое средство обладает своим
        эффектом и предназначено для определенного этапа очистки воды бассейна.</p>
      <ul class="products__list">
        <product-card
          v-for="(item, index) in products" :key="index"
          :image="item.image"
          :scale="item.scale"
          :title="item.title"
          :description="item.description"
          :code="item.code"
          :price="item.price"
          :weight="item.weight"
          @open-popup="openPopup(item, 'products')"
        />
      </ul>
    </div>
    <popup-component
      :isOpened="isOpened"
      :dataPopup="dataPopup"
      :isProductPopup="isProductPopup"
      @open-popup="openPopup"
      @close-popup="closePopup"
    />
    <request-popup
      :isRequestPopup="isRequestPopup"
      @open-popup="openPopup"
      @close-popup="closePopup"
    />
    <success-popup
      :isSuccessPopup="isSuccessPopup"
      @open-popup="openPopup"
      @close-popup="closePopup"
    />
  </section>
</template>

<script>
import ProductCard from '../blocks/ProductCard.vue'
import PopupComponent from '../blocks/PopupComponent.vue'
import RequestPopup from '@/components/blocks/RequestPopup'
import SuccessPopup from '@/components/blocks/SuccessPopup'

export default {
  components: {
    SuccessPopup,
    RequestPopup,
    ProductCard,
    PopupComponent
  },
  data () {
    return {
      products: [
        {
          image: '/images/products/7011.png',
          scale: '0.8',
          title: 'Дезинфектор кальций-хлор 92%',
          description: 'Для дезинфекции воды и санитарной обработки различных поверхностей',
          price: '1120',
          weight: '1.6 кг',
          code: 'Артикул: 701',
          specification: [
            {
              title: 'Состав:',
              text: 'Гипохлорит кальция, хлорид натрия, кальций углекислый.'
            },
            {
              title: 'Описание:',
              text: 'Гранулы белого или слабокрашенного цвета с запахом хлора.'
            },
            {
              title: 'Назначение:',
              text: 'Для дезинфекции воды плавательных бассейнов и санитарной обработки различных поверхностей. Препарат быстро и эффективно действует на бактерии, вирусы и грибы, разрушает  органические вещества, обуславливающие мутность воды.'
            },
            {
              title: 'Способ применения:',
              text: 'Дозировка средства зависит от количества купающихся, количества солнечных дней, типа бассейна (закрытый или открытый), температуры воздуха, атмосферных осадков, попадающих в бассейн. '
            },
            {
              title: 'Срок годности / Гарантийный срок хранения:',
              text: '5 лет с даты изготовления.'
            }
          ]
        },
        {
          image: '/images/products/7022.png',
          scale: '0.9',
          title: 'Дезинфектор кальций-хлор 92% ',
          description: 'Для дезинфекции воды и санитарной обработки различных поверхностей',
          price: '1720',
          weight: '2.7 кг',
          code: 'Артикул: 702',
          benefit: 'Экономичная упаковка - хватит на весь сезон!',
          specification: [
            {
              title: 'Состав:',
              text: 'Гипохлорит кальция, хлорид натрия, кальций углекислый.'
            },
            {
              title: 'Описание:',
              text: 'Гранулы белого или слабокрашенного цвета с запахом хлора.'
            },
            {
              title: 'Назначение:',
              text: 'Для дезинфекции воды плавательных бассейнов и санитарной обработки различных поверхностей. Препарат быстро и эффективно действует на бактерии, вирусы и грибы, разрушает  органические вещества, обуславливающие мутность воды.'
            },
            {
              title: 'Способ применения:',
              text: 'Дозировка средства зависит от количества купающихся, количества солнечных дней, типа бассейна (закрытый или открытый), температуры воздуха, атмосферных осадков, попадающих в бассейн.'
            },
            {
              title: 'Срок годности / Гарантийный срок хранения:',
              text: '5 лет с даты изготовления.'
            }
          ]
        },
        {
          image: '/images/products/7033.png',
          scale: '0.7',
          title: 'Дезинфектор МСХ КД 200 г',
          description: 'Для длительного обеззараживания и осветления воды бассейнов',
          price: '998',
          weight: '1 кг',
          code: 'Артикул: 703',
          specification: [
            {
              title: 'Состав:',
              text: 'Трихлоризоциануровая кислота, сульфат алюминия, сульфат меди.'
            },
            {
              title: 'Описание:',
              text: 'Таблетки 200 г белого цвета с синими вкраплениями, с запахом хлора.'
            },
            {
              title: 'Назначение:',
              text: 'Для длительного обеззараживания и осветления воды частных плавательных бассейнов объёмом от 15 м3. Свободный хлор, выделяемый средством, устойчив к УФ-излучению, может использоваться в воде любой жесткости.'
            },
            {
              title: 'Антимикробная активность:',
              text: 'Бактерицидная, вирулицидная активность и действие на вегетативные формы спорообразующих микроорганизмов.'
            },
            {
              title: 'Способ применения:',
              text: 'Обеспечить значение рН воды бассейна в пределах 7,2–7,6. Провести шоковое хлорирование гранулами «Аквадача».'
            },
            {
              title: 'Срок годности / Гарантийный срок хранения:',
              text: '3 года с даты изготовления.'
            }
          ]
        },
        {
          image: '/images/products/7099.png',
          scale: '0.7',
          title: 'Дезинфектор МСХ КД 20 г',
          description: 'Для длительного обеззараживания и осветления воды бассейнов',
          price: '984',
          weight: '1 кг',
          code: 'Артикул: 709',
          specification: [
            {
              title: 'Состав:',
              text: 'Трихлоризоциануровая кислота, сульфат алюминия, сульфат меди.'
            },
            {
              title: 'Описание:',
              text: 'Таблетки 20 г белого цвета с синими вкраплениями, с запахом хлора.'
            },
            {
              title: 'Назначение:',
              text: 'Для длительного обеззараживания и осветления воды част-ных плавательных бассейнов объёмом от 15 м3. Свободный хлор, выделяемый средством, устойчив к УФ-излучению, может использоваться в воде любой жесткости.'
            },
            {
              title: 'Антимикробная активность:',
              text: 'Бактерицидная, вирулицидная активность и действие на вегетативные формы спорообразующих микроорганизмов.'
            },
            {
              title: 'Способ применения:',
              text: 'Обеспечить значение рН воды бассейна в пределах 7,2–7,6. Провести шоковое хлорирование гранулами «Аквадача».'
            },
            {
              title: 'Срок годности / Гарантийный срок хранения:',
              text: '3 года с даты изготовления.'
            }
          ]
        },
        {
          image: '/images/products/7055.png',
          scale: '0.8',
          title: 'pH - минус',
          description: 'Для понижения уровня pH воды плавательных бассейнов до нормы',
          price: '788',
          weight: '1.6 кг',
          code: 'Артикул: 705',
          benefit: 'Экономичная упаковка - хватит на весь сезон!',
          specification: [
            {
              title: 'Состав:',
              text: 'Бисульфат натрия, сульфат натрия.'
            },
            {
              title: 'Назначение:',
              text: 'Для понижения уровня рН воды плавательных бассейнов до нормы.'
            },
            {
              title: 'Способ применения:',
              text: 'Для поддержания оптимального значения рН воды бассейна в пределах 7,2-7,6 необходимо 2-3 раза в неделю проводить контрольные замеры тестером. Значение рН выше 7,6 должно быть снижено до рекомендуемого добавлением средства «рН-минус» АкваДача.'
            },
            {
              title: 'Срок годности / Гарантийный срок хранения:',
              text: '2 года с даты изготовления.'
            }
          ]
        },
        {
          image: '/images/products/7066.png',
          scale: '1',
          title: 'Альгицид',
          description: 'Для уничтожения водорослей в бассейне и предупреждения их образования',
          price: '532',
          weight: '3 л',
          code: 'Артикул: 706',
          benefit: 'Экономичная упаковка - хватит на весь сезон!',
          specification: [
            {
              title: 'Состав:',
              text: 'Водный раствор ЧАС и комплексного соединения меди.'
            },
            {
              title: 'Назначение:',
              text: 'Уничтожает водоросли в бассейне и предупреждает их образование. Рекомендуется применять в сочетании с дезинфицирующими средствами «АкваДача».'
            },
            {
              title: 'Свойства:',
              text: 'Совместим с любыми фильтровальными установками, дезинфектантами и средствами водоподготовки «АкваДача». Не изменяет уровень рН воды. Эффективен в течение длительного времени.'
            },
            {
              title: 'Срок годности / Гарантийный срок хранения:',
              text: '3 года с даты изготовления'
            }
          ]
        },
        {
          image: '/images/products/7077.png',
          scale: '1',
          title: 'Чистая вода',
          description: 'Для уничтожения бактерий, микробов и зелени, осветления и смягчения воды, убирает железо',
          price: '1094',
          weight: '3 л',
          code: 'Артикул: 707',
          benefit: 'Экономичная упаковка - хватит на весь сезон!',
          specification: [
            {
              title: 'Состав:',
              text: 'Водный раствор ЧАС (менее 30%), поликарбоновой кислоты (менее 5%), полиоксихлорида алюминия (менее 5%), модифицирующих добавок.'
            },
            {
              title: 'Назначение:',
              text: 'Уничтожает водоросли в бассейне и предупреждает их образование. Устраняет мутность воды. Уничтожает патогенные бактерии, вирусы, грибы. Предотвращает появление ржавчины и способствует ее удалению.'
            },
            {
              title: 'Свойства:',
              text: 'Предотвращает  образование известковых отложений на твердой поверхности чаши бассейна и оборудования. Не изменяет уровень рН воды.  Может использоваться в бассейнах с неспокойной водой (горки, противотоки, струйные аттракционы и т.д.) Совместим с любыми фильтровальными установками, дезинфектантами и вспомогательными средствами обработки, используемыми в бассейнах. Эффективен в течении длительного времени.'
            },
            {
              title: 'Срок годности / Гарантийный срок хранения:',
              text: '2 года с даты изготовления.'
            }
          ]
        },
        {
          image: '/images/products/7088.png',
          scale: '1',
          title: 'Коагулянт',
          description: 'Для очистки воды бассейна от мутности и взвешенных частиц',
          price: '578',
          weight: '3 л',
          code: 'Артикул: 708',
          benefit: 'Экономичная упаковка - хватит на весь сезон!',
          specification: [
            {
              title: 'Состав:',
              text: 'Водный раствор полиоксихлорида  алюминия (гидроксихлорида алюминия, полихлорида алюминия).'
            },
            {
              title: 'Назначение:',
              text: 'Для очистки воды плавательных бассейнов от мутности и взвешенных частиц.'
            },
            {
              title: 'Способ применения:',
              text: 'Перед  применением коагулянта следует проверить и при необходимости довести до нормы значение рН воды до 7,2-7,6 и свободного хлора до 0,3-0,5мг/л средствами «Аквадача». Рекомендуется вводить коагулянт поэтапно, небольшими дозами. Внимание: во время действия коагулянта возможет эффект «белёсой» воды, который пропадает после выпадения в осадок хлопьев и взвеси.'
            },
            {
              title: 'Срок годности / Гарантийный срок хранения:',
              text: '12 месяцев с даты изготовления.'
            }
          ]
        },
        {
          image: '/images/products/710.png',
          scale: '1',
          title: 'Дезинфектор',
          description: 'Для длительного обеззараживания и осветления воды частных плавательных бассейнов объёмом от 15 м³',
          price: '578',
          weight: '1 кг | 5шт.',
          code: 'Артикул: 710',
          benefit: 'Экономичная упаковка - хватит на весь сезон!',
          specification: [
            {
              title: 'Состав:',
              text: 'Трихлоризоциануровая кислота, сульфат алюминия, сульфат меди.'
            },
            {
              title: 'Описание:',
              text: 'Таблетки 200 г белого цвета с синими вкраплениями, с запахом хлора.'
            },
            {
              title: 'Назначение:',
              text: 'Для длительного обеззараживания и осветления воды частных плавательных бассейнов объёмом от 15 м³. Свободный хлор, выделяемый средством, устойчив к УФ-излучению, может использоваться в воде любой жесткости.'
            },
            {
              title: 'Способ применения:',
              text: 'Обеспечить значение рН воды бассейна в пределах 7,2–7,6. Провести шоковое хлорирование дезинфектором «Аквадача» в гранулах.'
            },
            {
              title: 'Срок годности / Гарантийный срок хранения:',
              text: '3 года с даты изготовления.'
            }
          ]
        },
        {
          image: '/images/products/711.png',
          scale: '1',
          title: 'Дезинфектор',
          description: 'Для длительного обеззараживания и осветления воды частных плавательных бассейнов объёмом от 15 м³',
          price: '578',
          weight: '1 кг | 50шт.',
          code: 'Артикул: 711',
          benefit: 'Экономичная упаковка - хватит на весь сезон!',
          specification: [
            {
              title: 'Состав:',
              text: 'Трихлоризоциануровая кислота, сульфат алюминия, сульфат меди.'
            },
            {
              title: 'Описание:',
              text: 'Таблетки 20 г белого цвета с синими вкраплениями, с запахом хлора.'
            },
            {
              title: 'Назначение:',
              text: 'Для длительного обеззараживания и осветления воды частных плавательных бассейнов объёмом от 15 м³. Свободный хлор, выделяемый средством, устойчив к УФ-излучению, может использоваться в воде любой жесткости.'
            },
            {
              title: 'Способ применения:',
              text: 'Обеспечить значение рН воды бассейна в пределах 7,2–7,6. Провести шоковое хлорирование дезинфектором «Аквадача» в гранулах.'
            },
            {
              title: 'Срок годности / Гарантийный срок хранения:',
              text: '12 месяцев с даты изготовления.'
            }
          ]
        }
      ],
      isOpened: false,
      dataPopup: {},
      isProductPopup: true,
      isRequestPopup: false,
      isSuccessPopup: false,
      scrollWidth: 0
    }
  },
  mounted () {
    const params = new URLSearchParams(window.location.search)
    const itemID = params.get('item')
    if (itemID) {
      const item = this.products[itemID - 1]
      if (item) {
        this.openPopup(item, 'products')
      }
    }
  },
  methods: {
    widthScrollBar () {
      const div = document.createElement('div')
      div.style.overflowY = 'scroll'
      div.style.width = '50px'
      div.style.height = '50px'
      document.body.append(div)
      const scrollWidth = div.offsetWidth - div.clientWidth
      div.remove()
      this.scrollWidth = `${scrollWidth}px`
    },
    scrollBarVisibility () {
      this.widthScrollBar()
      if (this.isProductPopup || this.isRequestPopup || this.isSuccessPopup) {
        document.querySelector('html').style.overflow = 'hidden'
        document.querySelector('html').style.marginRight = this.scrollWidth
        document.querySelector('header').style.marginRight = this.scrollWidth
      } else {
        document.querySelector('html').style.overflow = 'auto'
        document.querySelector('html').style.marginRight = '0px'
        document.querySelector('header').style.marginRight = '0px'
      }
    },
    closePopup (popup) {
      this.isOpened = false
      this.isProductPopup = false
      this.isRequestPopup = false
      this.isSuccessPopup = false
      switch (popup) {
        case 'products':
          this.isOpened = true
          this.isProductPopup = true
          break
        case 'request':
          this.isRequestPopup = true
          break
        case 'success':
          this.isSuccessPopup = true
          break
      }
      this.scrollBarVisibility()
    },
    openPopup (data, popup) {
      this.isOpened = false
      this.isProductPopup = false
      this.isRequestPopup = false
      this.isSuccessPopup = false
      switch (popup) {
        case 'products':
          this.isOpened = true
          this.isProductPopup = true
          break
        case 'request':
          this.isRequestPopup = true
          break
        case 'success':
          this.isSuccessPopup = true
          break
      }
      if (data) {
        this.dataPopup = data
      }
      this.scrollBarVisibility()
    }
  }
}
</script>

<style lang="scss" scoped>
.products {
  background: url(@/assets/images/products/background.png);
  background-size: cover;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 48px;
    padding-bottom: 72px;
    padding-bottom: 93px;
  }

  &__title {
    text-align: center;
  }

  &__description {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 46px;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 26px;
    width: 100%;
  }

  @media (min-width: $mobile-max) {
    &__list {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 16px;
      grid-row-gap: 16px;
    }
  }

  @media (min-width: $tablet-min) {
    &__list {
      grid-gap: 24px;
    }
    &__title {
      max-width: 500px;
    }
    &__description {
      max-width: 700px;
    }
  }
  @media (min-width: $desktop) {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 164px;
      padding-top: 37px;
      padding-bottom: 131px;
    }
    &__title {
      max-width: 600px;
      text-align: center;
    }
    &__description {
      text-align: center;
      max-width: 503px;
      margin-top: 14px;
      font-size: 11px;
      margin-bottom: 46px;
    }
    &__list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 16px;
      grid-row-gap: 16px;
    }
  }
  @media (min-width: 1440px) {
    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 67px;
      padding-bottom: 164px;
      padding-bottom: 211px;

    }
    &__title {
      max-width: 916px;
      text-align: center;
    }
    &__description {
      max-width: 833px;
      margin-top: 27px;
      margin-bottom: 80px;
      font-family: $mainFont;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 26px;
      text-align: center;
      color: #565656;
    }
    &__list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 27px;
      grid-row-gap: 26px;
    }
  }
}
</style>
