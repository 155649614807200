<template>
  <header class="header" v-on="handleScroll" :class="{scroll: isScroll}">
    <div class="header__container container">
      <a class="header__logo" href="#main">
        <logo-component
          :color="'#ffffff'"
        />
      </a>
      <ul class="header__menu menu menu--desktop">
        <li><a href="#products">Каталог товаров</a></li>
        <li><a href="#benefits">Преимущества</a></li>
        <li class="li_button">
          <button-default
                :color="'blue'"
                :text="'Для оптовых покупателей'"
                @click="openPopup('request')"
              />
        </li>
        <li class="li_button"><button-default
                :color="'green'"
                :text="'Где купить'"
                @click="openPopup('modal')"
              /></li>
      </ul>
      <div class="header__burger" @click="menuOpened = !menuOpened" :class="{opened: menuOpened}">
        <span></span>
      </div>
<!--      <div class="header__social">-->
<!--        <a class="vk" href="#">-->
<!--        </a>-->
<!--        <a class="youtube" href="#">-->
<!--        </a>-->
<!--      </div>-->
    </div>
    <modal-component
      :isOpened="modal"
      @open-popup="openPopup"
      @close-popup="closePopup"
    />
    <request-popup
      :isRequestPopup="isRequestPopup"
      @open-popup="openPopup"
      @close-popup="closePopup"
    />
    <success-popup
      :isSuccessPopup="isSuccessPopup"
      @open-popup="openPopup"
      @close-popup="closePopup"
    />
    <transition name="fade">
      <div v-if="menuOpened" class="header__menu menu menu--mobile" :class="{opened: menuOpened}">
        <ul class="menu__list">
          <li @click="menuOpened = !menuOpened"><a href="#products">Каталог товаров</a></li>
          <li @click="menuOpened = !menuOpened"><a href="#benefits">Преимущества</a></li>
          <li @click="menuOpened = !menuOpened" class="li_button li_button_mobile"><button-default
                :color="'blue'"
                :text="'Для оптовых покупателей'"
                @click="openPopup('request')"
              /></li>
          <li @click="menuOpened = !menuOpened" class="li_button li_button_mobile"><button-default
                :color="'green'"
                :text="'Где купить'"
                @click="openPopup('modal')"
              /></li>
        </ul>
      </div>
    </transition>
  </header>
</template>

<script>
import LogoComponent from './ui/LogoComponent.vue'
import ModalComponent from './blocks/ModalComponent.vue'
import ButtonDefault from './ui/ButtonDefault.vue'
import RequestPopup from '@/components/blocks/RequestPopup'
import SuccessPopup from '@/components/blocks/SuccessPopup'

export default {
  components: {
    LogoComponent,
    ButtonDefault,
    ModalComponent,
    SuccessPopup,
    RequestPopup
  },
  data () {
    return {
      isScroll: false,
      limitPosition: 80,
      lastPosition: 0,
      modal: false,
      isRequestPopup: false,
      isSuccessPopup: false,
      menuOpened: false
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
        this.isScroll = true
      }
      if (this.lastPosition > window.scrollY && this.limitPosition > window.scrollY) {
        this.isScroll = false
      }
      this.lastPosition = window.scrollY
    },
    widthScrollBar () {
      const div = document.createElement('div')
      div.style.overflowY = 'scroll'
      div.style.width = '50px'
      div.style.height = '50px'
      document.body.append(div)
      const scrollWidth = div.offsetWidth - div.clientWidth
      div.remove()
      this.scrollWidth = `${scrollWidth}px`
    },
    scrollBarVisibility () {
      if (this.isProductPopup || this.isRequestPopup || this.isSuccessPopup || this.isQuizPopup) {
        document.querySelector('html').style.overflow = 'hidden'
        document.querySelector('html').style.marginRight = this.scrollWidth
        document.querySelector('header').style.marginRight = this.scrollWidth
      } else {
        document.querySelector('html').style.overflow = 'auto'
        document.querySelector('html').style.marginRight = '0px'
        document.querySelector('header').style.marginRight = '0px'
      }
    },
    closePopup (popup, data) {
      this.isOpened = false
      this.modal = false
      this.isQuizPopup = false
      this.isRequestPopup = false
      this.isSuccessPopup = false
      this.scrollBarVisibility()
      this.nextStep()
      if (popup === 'success') {
        this.isSuccessPopup = true
      }
    },
    openPopup (popup) {
      switch (popup) {
        case 'quiz':
          this.isOpened = true
          this.isQuizPopup = true
          break
        case 'request':
          this.isRequestPopup = true
          break
        case 'modal':
          this.modal = true
          break
      }
      this.scrollBarVisibility()
    },
    nextStep (value) {
      this.isQuizStart = value
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active {
  animation: opened 0.5s;
}
.fade-leave-active {
  animation: closed .5s;
}

@keyframes opened {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes closed {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.header {
  position: fixed;
  left: 0;
  right: 0;
  background-color: $mainColor;
  height: 43px;
  color: $baseColor;
  font-family: $mainFont;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  text-align: right;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: height .5s ease;
  &.opened {
    margin-right: calc(100% - 100vw);
  }

  &__container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
  }

  &__burger {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & span {
      width: 15px;
      height: 2px;
      background-color: $baseColor;
      display: block;
      position: relative;
      transition: all .5s ease;
      &::before,
      &::after {
        content: '';
        width: 15px;
        height: 2px;
        background-color: $baseColor;
        display: block;
        position: absolute;
        transition: all .5s ease;
      }
      &::before {
        top: -6px;
      }

      &::after {
        bottom: -6px;
      }
    }
    &.opened {
      & span {
        background-color: transparent;

        &::before {
          top: 0;
          transform: rotate(45deg);
        }

        &::after {
          top: 0;
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__logo {
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 10px;
    height: 68px;
    &::after {
      transition: all .5s;
      content: '';
      background-color: $mainColor;
      position: absolute;
      height: 67px;
      width: 112px;
      border-radius: 0 0 11.1px 11.1px;
      left: -7px;
      top: 0;
      z-index: -1;
    }

    & svg {
      max-width: 78px;

    }
  }

  &__social {
    & a + a {
      margin-left: 8px;
    }
  }

  &__menu {
    height: 100vh;
    width: 100vw;
    background-color: $mainColor;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & li {
      text-align: center;
      font-size: 20px;
    }

    & li + li {
      margin-top: 40px;
    }
    .li_button button{
      text-align: center;
      font-size: 16px;
      height: max-content;
      background-color:unset;
      border:none;
    }
    .li_button button:hover{
      background:unset;
      color:#fff;
    }
    .li_button_mobile button{
      font-size: 20px;
    }
  }

  @media (min-width: $mobile-max) {
  }
  @media (min-width: $tablet-min) {
    height: 48px;
    &__container {
      flex-direction: row;
    }
    &__burger {
      justify-content: flex-start;
      & span {
        width: 24px;

        &::before,
        &::after {
          width: 24px;
        }
      }
    }
    &__logo {
      & svg {
        max-width: 100px;
      }
      &::after{
        height: 68px;
        width: 120px;
        left: 0;
      }
    }
  }
  @media (min-width: $tablet-max) {
  }
  @media (min-width: 1200px) {
    height: 50px;
    &__burger {
      display: none;
    }
    &__menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 67%;
      height: auto;
      flex-direction: row;
      position: relative;

      & li {
        font-size: 11px;
        letter-spacing: -0.3px;
      }

      .li_button button{
        font-size: 11px;
        letter-spacing: -0.3px;
      }

      & li + li {
        margin-top: 0;
      }
    }
    &.scroll {
      height: 64px;

      .header__logo {
        transition: all .5s;

        &::after {
          transition: all .5s;
          height: 64px;
        }

        & svg {
          left: 0;
          top: 0;
          width: 100px;
          transform: translateY(-50%);
          transition: all .5s;
        }
      }
    }
    &__container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
    }
    &__logo {
      position: relative;
      margin-right: 24px;
      transition: all .5s;
      left: 0;
      transform: none;
      height: auto;
      width: 124px;
      min-width: 124px;

      &::after {
        width: 124px;
        height: 84px;
        left: 0;
        top: -40px;
        z-index: -1;
        border-radius: 0 0 6px 6px;
      }

      &:hover {
        cursor: pointer;
      }

      & svg {
        transition: all .5s;
        position: absolute;
        width: 88px;
        top: 9px;
        left: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
        max-width: none;

        & path {
          &.fil0 {
            fill: $mainColor;
          }
        }
      }
    }
    &__social {
      display: flex;
      margin-left: 0;
      width: 100%;
      margin-right: 45px;
      max-width: 109px;
      width: 100%;
      justify-content: flex-end;

      & a {
        display: block;
        color: inherit;
        transition: all .5s;
        font-size: 12px;
        &:hover {
          opacity: .5;
        }
      }
    }
  }
  @media (min-width: 1440px) {
    height: 80px;
    &__burger {
      display: none;
    }
    &__menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 67%;
      height: auto;
      flex-direction: row;
      position: relative;

      & li {
        font-size: 16px;
      }

      .li_button button {
        font-size: 16px;
      }

      & li + li {
        margin-top: 0;
      }
    }
    &.scroll {
      height: 64px;

      .header__logo {
        transition: all .5s;

        &::after {
          transition: all .5s;
          height: 64px;
        }

        & svg {
          left: 0;
          top: 0;
          width: 100px;
          transform: translateY(-50%);
          transition: all .5s;
        }
      }
    }
    &__container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
    }
    &__logo {
      position: relative;
      margin-right: 26px;
      transition: all .5s;
      left: 0;
      transform: none;
      height: auto;
      width: 200px;
      min-width: 200px;

      &::after {
        height: 111px;
        width: 200px;
        left: 0;
        top: -40px;
        z-index: -1;
      }

      &:hover {
        cursor: pointer;
      }

      & svg {
        transition: all .5s;
        position: absolute;
        top: 15px;
        left: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
        max-width: none;
        width: auto;
        & path {
          &.fil0 {
            fill: $mainColor;
          }
        }
      }
    }
    &__social {
      display: flex;
      margin-left: 0;
      width: 100%;
      margin-right: 48px;
      max-width: 168px;
      width: 100%;
      justify-content: flex-end;

      & a {
        display: block;
        color: inherit;
        transition: all .5s;

        &:hover {
          opacity: .5;
        }

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}

.menu {
  &--desktop {
    display: none;
  }

  @media (min-width: 1200px) {
    &--desktop {
      display: flex;
      font-family: 'OpenSansReg';
      font-weight: 400;
      font-size: 17px;
      line-height: 150%;
      text-align: right;
    }
    &--mobile {
      display: none;
    }
  }
}
</style>
