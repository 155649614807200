<template>
  <main>
    <v-parallax class="parallax first" src="/images/parallax/1.png" />
    <v-parallax class="parallax second" src="/images/parallax/2.png" />
    <v-parallax class="parallax third" src="/images/parallax/3.png" />
    <v-parallax class="parallax forth" src="/images/parallax/4.png" />
    <v-parallax class="parallax fifth" src="/images/parallax/5.png" />
    <main-component v-if="main"/>
    <benefits-component v-if="benefits"/>
    <products-component v-if="products"/>
    <v-lazy min-height="200" v-model="videoLoad" :options="{ threshold: 0.5 }">
      <section>
        <div class="container container_video">
          <v-lazy
            class="desktop_video"
            min-height="100"
            v-model="videoLoad"
            :options="{ threshold: 0.5 }"
          >
            <video class="desktop_video" src="/video/video.mp4" autoplay loop playsinline muted></video>
          </v-lazy>
          <v-lazy
            class="mobile_video"
            min-height="100"
            v-model="videoLoad"
            :options="{ threshold: 0.5 }"
          >
            <video class="mobile_video" src="/video/video_mobile.mp4" autoplay loop playsinline muted></video>
          </v-lazy>
        </div>
      </section>
    </v-lazy>
    <banner-form v-if="banner"/>
  </main>
</template>

<script>
import MainComponent from '../index/MainComponent.vue'
import BenefitsComponent from '../index/BenefitsComponent.vue'
import ProductsComponent from '../index/ProductsComponent.vue'
import BannerForm from '../index/BannerForm.vue'

export default {
  components: {
    MainComponent,
    BenefitsComponent,
    ProductsComponent,
    BannerForm
  },
  data () {
    return {
      videoLoad: false,
      main: true,
      benefits: true,
      products: true,
      banner: true,
      problems: true,
      banner2: true
    }
  }
}
</script>

<style lang="scss">

html {
  font-style: normal;
  font-weight: 400;
  font-size: 12.8793px;
  line-height: 19px;
  color: #565656;
}

.parallax {
  display: none;
  @media (min-width: 1200px) {
    display: block;
    position: absolute;
    overflow: visible;
  }
}

.first {
  width: 96px;
  top: 153px;
}
.second {
  width: 134px;
  top: 708px;
  right: 0;
}
.third {
  top: 1260px;
  width: 134px;
}
.forth {
  bottom: 1469px;
  width: 126px;
  left: -7px;
}
.fifth {
  right: 0;
  bottom: 969px;
  width: 134px;
}
.container_video .video-block img, .container_video video{
  max-width:1280px;
  width:100%;
  max-height:450px;
  height:100%;
}
.container_video{
  padding-bottom:60px;
}
.container_video video{
  max-width:100%;
  max-height:none;
}
.video-block{
  position:relative;
}
.play_video{
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  padding: 0;
  width: 150px;
  height: 150px;
  opacity: .7;
  border: none;
  background-color: transparent;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: .5s;
  opacity:0.5;
}
.video-block:hover .play_video{
  opacity: 1;
}
.play_video img{
  width: 150px;
  height: 150px;
}
.mobile_video{
  display:none;
}
@media (max-width: 768px){
  .desktop_video{
    display:none;
  }
  .mobile_video{
    display:block;
  }
}
</style>
