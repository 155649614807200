<template>
  <article class="product" @click="$emit('openPopup')">
    <p class="product__code">{{ code }}</p>
    <p class="product__weight">{{ weight }}</p>
    <div class="product__image">
      <img :src="image" alt="" :style="'max-width: calc(min(219px, 100%)*' + scale +');'">
    </div>
    <h3 class="product__title title title--medium">{{ title }}</h3>
    <p class="product__description">{{ description }}</p>

    <button-default
      class="product__button"
      :text="'Подробнее'"
      :svg="'arrow'"
      :color="'green'"
    />
  </article>
</template>

<script>
import ButtonDefault from '../ui/ButtonDefault.vue'

export default {
  props: ['image', 'scale', 'title', 'description', 'price', 'code', 'weight'],
  components: {
    ButtonDefault
  }
}
</script>

<style lang="scss" scoped>
.product {
  background-color: $baseColor;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 21px 31px 28px;
  transition: all .5s ease;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:hover {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
  }

  &__weight{
    position: absolute;
    z-index: 1;
    right: 24px;
    top: 24px;
    color: #bbbbbb;
    font-size: 18px;
    line-height: 24px;
    margin-top:0px;
    font-weight: 600;
  }
  &__code {
    position: absolute;
    z-index: 1;
    left: 24px;
    top: 24px;
    color: #bbbbbb;
    font-size: 15px;
    line-height: 24px;
  }

  &__image {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: end;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 40px;

  & img {
    display: block;
    max-width: calc(min(219px, 100%) * var(scale));
    width: 100%;
    cursor: pointer;
  }
  }
  &__title {
    text-align: center;
    max-width: 285px;
    margin-top: 17px;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.03em;
    margin-top: 0;
  }

  &__description {
    text-align: center;
    max-width: 280px;
    margin-top: 20px;
    font-family: $mainFont;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #565656;
    min-height: 78px;
  }

  &__price {
    margin-top: 18px;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    margin-top: 53px;

    & span {
      font-family: 'OpenSansBold';
      font-weight: 700;
      font-size: 24px;
      line-height: 38px;
      color: $mainColor;
      margin-left: 6px;
    }
  }

  &__button {
    margin-top: 15px;
    font-size: 17px;
    max-width: 240px;
  }

  @media (min-width: $desktop) {
    padding: 4px 8px 18px;
    &__weight {
      right: 12px;
      top: 4px;
      font-size: 9px;
    }
    &__code {
      left: 12px;
      top: 4px;
      font-size: 9px;
    }
    &__title {
      font-size: 15px;
      line-height: 17px;
    }
    &__description {
      max-width: 163px;
      margin-top: 14px;
      font-size: 10px;
      line-height: 16px;
      min-height: 78px;
    }
    &__price {
      margin-top: 35px;
      font-size: 11px;
      line-height: 11px;
      & span {
        font-size: 15px;
        line-height: 15px;
      }
    }
    &__button {
      margin-top: 13px;
      max-width: 149px;
      font-size: 10px;
      height: 40px;
      &::after {
        font-size: 7px;
      }
    }
  }
  @media (min-width: 1440px) {
    background-color: $baseColor;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 21px 31px 28px;
    transition: all .5s ease;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;

    &:hover {
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.25);
    }

    &__weight{
      position: absolute;
      z-index: 1;
      right: 24px;
      top: 24px;
      color: #bbbbbb;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
    }
    &__code {
      position: absolute;
      z-index: 1;
      left: 24px;
      top: 24px;
      color: #bbbbbb;
      font-size: 15px;
      line-height: 24px;
    }

    &__image {
      flex: 1 1 auto;
      display: flex;
      justify-content: center;
      align-items: end;
      width: 100%;
      margin-bottom: 20px;
      margin-top: 40px;

      & img {
        display: block;
        max-width: calc(min(219px, 100%) * var(scale));
        width: 100%;
        cursor: pointer;
      }
    }

    &__title {
      text-align: center;
      max-width: 285px;
      margin-top: 17px;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      letter-spacing: 0.03em;
      margin-top: 0;
    }

    &__description {
      text-align: center;
      max-width: 280px;
      margin-top: 20px;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      min-height: 78px;
    }

    &__price {
      margin-top: 18px;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      margin-top: 53px;

      & span {
        font-family: 'OpenSansBold';
        font-weight: 700;
        font-size: 24px;
        line-height: 38px;
        color: $mainColor;
        margin-left: 6px;
      }
    }

    &__button {
      margin-top: 15px;
      font-size: 17px;
      max-width: 240px;
      height: 70px;
      &::after {
        font-size: 15px;
      }
    }
    &__price {
      margin-top: 52px;
    }
  }
}
</style>
