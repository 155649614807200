<template>
    <section class="popupnew" v-if="isOpened">
      <div class="popup__area" @click="closePopup"></div>
      <transition name="fade" mode="out-in" appear>
        <div class="popupnew__container container">
          <p class="popupnew__name_modal"><b>Всю продукцию</b><br> можно купить в интернете</p>
          <button class="popup__button button button--close" @click="closePopup"></button>
          <div class="popupnew__block_icon">
            <p class="popupnew__txt_modal">Выберите маркетплейс нажав на значок ниже:</p>
            <div class="popupnew__img_modal">
              <a href="https://www.wildberries.ru/seller/1317420?sort=popular&page=1&fbrand=280298" class="popupnew__icon_modal">
                <img src="/images/market-icon/wildber.png" alt="wildberries">
                <p class="popupnew__upper_case">wildberries</p>
              </a>
              <a class="popupnew__icon_modal" href="https://www.ozon.ru/seller/aqualeon-1239176/brand/akvadacha-100357132/?miniapp=seller_1239176">
                <img src="/images/market-icon/ozon.png" alt="ozon">
                <p class="popupnew__upper_case">ozon</p>
              </a>
              <a class="popupnew__icon_modal" href="https://market.yandex.ru/search?businessId=58301735&text=%D0%B0%D0%BA%D0%B2%D0%B0%D0%B4%D0%B0%D1%87%D0%B0&rs=eJwzamIOYDzKyHBA0RZIMoTsAZIJJ22A5IJqEKnAvxtIOnCD2A-EdwHJhmyQGgZrsJr5IJJhAkj8ASNI5YMCa5C4KMi0BVgkZdglRFWIFIEJJvACzLhwSVLkF5XkEqFD2Dx9yARB1GQeod-sDkQSRDC9glviCyYSaYXbsfpL4YLKILdkMfiEyYCDL5wDMwOxpkcsNRkAkNcSC2QzvIPQc-gf0yD8ROWAKWPQAWOQ5iO-wEmxlgA7e3oQPk8oaFNnBSIRvMLgDb4gomVUD2NqSA2SvBegPBpp0Duz8Q7CMdkJoFU8F2zQSFWIICOBym2AAAeuGDeQ%2C%2C&allowCollapsing=1&local-offers-first=0">
                <img src="/images/market-icon/yandex.png" alt="Yandex market">
                <p class="popupnew__upper_case">яндекс маркет</p>
              </a>
              <a class="popupnew__icon_modal" href="https://megamarket.ru/shop/aqualeon/catalog/dacha-sezonnye-tovary/#?filters=%7B%222B0B1FF4756D49CF84B094522D57ED3D%22%3A%5B%22%D0%90%D0%BA%D0%B2%D0%B0%D0%94%D0%B0%D1%87%D0%B0%22%5D%7D">
                <img src="/images/market-icon/sbermarket.png" alt="megamrket">
                <p class="popupnew__upper_case">мегамаркет</p>
              </a>
              <a class="popupnew__icon_modal" href="https://volgograd.leroymerlin.ru/search/?q=Aqualeon">
                <img src="/images/market-icon/leroy.png" alt="lerua merlen">
                <p class="popupnew__upper_case">леруа мерлен</p>
              </a>
            </div>
          </div>
        </div>
      </transition>
    </section>
</template>

<script>

export default {
  props: ['isOpened'],

  data () {
    return {
      property1: true
    }
  },
  methods: {
    closePopup () {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="scss" scoped>

.fade-enter-active {
  animation: opened .5s;
}

.fade-leave-active {
  animation: closed .5s;
}

@keyframes opened {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes closed {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 102;
  background-color: rgba(180, 180, 180, .4);
  transition: all .5s ease;
  &__area {
    position: absolute;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(5px);
    z-index: 0;
    cursor: pointer;
  }

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 32px 20px;
    overflow-y: scroll;
    max-width: none;
    background-color: $baseColor;
  }

  &__logo {
    display: none;
  }

  @media (min-width: $tablet-min) {
    &__container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $baseColor;
      border-radius: 84px;
      overflow-y: visible;
      max-width: 1197px;
      height: 624px;
      padding: 58px 72px 50px;
    }
    &__logo {
      position: absolute;
      display: block;
      max-width: 112px;
      right: 72px;
      z-index: 10;
      top: 48px;
    }
  }
  @media (min-width: $desktop) {
    &__container {
      //position: absolute;
      //top: 50%;
      //left: 50%;
      //transform: translate(-50%, -50%);
      //background-color: $baseColor;
      //max-width: 1200px;
      //height: 570px;
      //padding: 50px;
    }
    &__logo {
      //position: absolute;
      //right: 50px;
      //z-index: 10;
    }
  }
}

.popupnew {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 102;
  background-color: rgba(180, 180, 180, .4);
  transition: all .5s ease;
  &__area {
    position: absolute;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(5px);
    z-index: 0;
    cursor: pointer;
  }

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 32px 20px;
    max-width: none;
    background-image: url(/src/assets/images/bg/bg_modal.png);
    background-size:100%;
  }
  &__name_modal{
    text-align: center;
    color: #1b1b1b;
    font-size: 38px;
    line-height:130%;
  }
  &__logo {
    display: none;
  }
  &__img_modal{
    display: flex;
    gap:30px;
    max-width:max-content;
    justifuy-content:center;
    margin:0 auto;
  }
  &__img_modal img{
    width: 90px;
    height: 90px;
    margin:0 auto;
  }
  &__block_icon{
    margin-top:340px;
    text-align:center;
  }
  &__icon_modal{
    text-align:center;
  }
  &__txt_modal{
    color: #1b1b1b;
    font-size: 25px;
    margin-bottom:20px;
  }
  &__upper_case{
    color: #1b1b1b;
    text-decoration:uppercase;
    font-size:18px;
    margin-top:10px;
  }
  @media (min-width: $tablet-min) {
    &__container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 84px;
      max-width: 1025px;
      height: 670px;
    }
    &__logo {
      position: absolute;
      display: block;
      max-width: 112px;
      right: 72px;
      z-index: 10;
      top: 48px;
    }
  }
  @media (max-width: 991px){
    &__block_icon{
      margin-top:200px;
    }
  }
  @media (max-width: 768px){
    &__container{
      max-width:unset;
      background-size:cover;
      display:flex;
      flex-direction:column;
      justify-content: space-between;
    }
    &__block_icon img{
      max-width: 90px;
      height: unset;
      width:100%;
    }
    &__block_icon{
      margin-top:100px;
    }
    &__upper_case{
      display:none;
    }
    &__img_modal{
      flex-wrap:wrap;
      justify-content: center;
    }
  }
  @media (max-width: 468px){
    &__img_modal{
      gap: 15px;
    }
    &__block_icon img{
      max-width: 60px;
    }
  }
}

template {
  display: block;
}

.button {
  &--close {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 26px;
    height: 26px;
    border: 2px solid $mainColor;
    border-radius: 50%;
    z-index: 100;
    transition: all .5s ease;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      right: 10px;
      height: 12px;
      width: 2px;
      background-color: $mainColor;
      border-radius: 5px;
      transition: all .5s ease;
      &:hover {
        opacity: .7;
        transition: all .5s ease;
      }
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
    &:hover {
      opacity: .7;
      transition: all .5s ease;
    }
    @media (min-width: $tablet-min) {
      right: 0;
      top: -2px;
      border: 2px solid $baseColor;
      &::before,
      &::after {
        background-color: $baseColor;
      }
    }
    @media (min-width: $desktop) {
      right: -7px;
      top: -2px;
    }
  }
}
</style>
